import './App.css';
import React, { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Footer, Header, NotFound, Waiting } from './components';
import { Layout } from 'antd';
import HomePage from './pages/Home';

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={<Waiting />}>
        <Layout style={{ minHeight: '100vh' }}>
          <Header />
          <Layout.Content style={{ padding: '0 50px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Routes>
              <Route
                path="/"
                element={<HomePage />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Layout.Content>
          <Footer />
        </Layout>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
