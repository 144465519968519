import React from 'react';
import { Layout } from 'antd';

const { Footer } = Layout;

const FooterComponent = () => {
    return (
        <Footer style={{ backgroundColor: '#001529', color: 'white', textAlign: 'center', padding: '20px 0', position: 'fixed', bottom: 0, width: '100%' }}>
            All in one ©2024 Created by thaiihuy
        </Footer>
    );
};

export default FooterComponent;