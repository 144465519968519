import { Breadcrumb, Card } from 'antd';
import React, { useState } from 'react';


const HomePage = () => {
    const [products] = useState([
        { id: 1, name: 'Product 1', price: 10, description: 'Description of product 1' },
        { id: 2, name: 'Product 2', price: 20, description: 'Description of product 2' },
        { id: 3, name: 'Product 3', price: 30, description: 'Description of product 3' },
        { id: 1, name: 'Product 1', price: 10, description: 'Description of product 1' },
        { id: 2, name: 'Product 2', price: 20, description: 'Description of product 2' },
        { id: 3, name: 'Product 3', price: 30, description: 'Description of product 3' },
        { id: 1, name: 'Product 1', price: 10, description: 'Description of product 1' },
        { id: 2, name: 'Product 2', price: 20, description: 'Description of product 2' },
        { id: 3, name: 'Product 3', price: 30, description: 'Description of product 3' },
        { id: 1, name: 'Product 1', price: 10, description: 'Description of product 1' },
        { id: 2, name: 'Product 2', price: 20, description: 'Description of product 2' },
        { id: 3, name: 'Product 3', price: 30, description: 'Description of product 3' },
        { id: 1, name: 'Product 1', price: 10, description: 'Description of product 1' },
        { id: 2, name: 'Product 2', price: 20, description: 'Description of product 2' },
        { id: 3, name: 'Product 3', price: 30, description: 'Description of product 3' },
        { id: 1, name: 'Product 1', price: 10, description: 'Description of product 1' },
        { id: 2, name: 'Product 2', price: 20, description: 'Description of product 2' },
        { id: 3, name: 'Product 3', price: 30, description: 'Description of product 3' },
        { id: 1, name: 'Product 1', price: 10, description: 'Description of product 1' },
        { id: 2, name: 'Product 2', price: 20, description: 'Description of product 2' },
        { id: 3, name: 'Product 3', price: 30, description: 'Description of product 3' },
    ]);
    return (
        <div>
            <Breadcrumb style={{ margin: '16px 0' }}>
                <Breadcrumb.Item>Home</Breadcrumb.Item>
                <Breadcrumb.Item>Products</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-content">
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                    {products.map(product => (
                        <Card key={product.id} title={product.name} style={{ width: 300, margin: '16px' }}>
                            <p>{product.description}</p>
                            <p>Price: ${product.price}</p>
                        </Card>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default HomePage;