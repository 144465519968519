import React from 'react';
import { Menu } from 'antd';

const HeaderComponent = () => {
    return (
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']}>
            <Menu.Item key="1">Trang chủ</Menu.Item>
            <Menu.Item key="2">About</Menu.Item>
        </Menu>
    );
};

export default HeaderComponent;